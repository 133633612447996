import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';
import { Theme } from '@sumup/design-tokens';

import * as ColumnLayoutService from '../../ColumnLayoutService';
import RichtextColumn from '../RichtextColumn';
import {
  Column as ColumnType,
  Alignments,
  ImagePreset,
  ImagePresetSize,
  SplitLayoutVerticalImageAlignment,
} from '../../interfaces';

import { ALIGNMENT, PRESET_IMAGE_SIZE } from '~/shared/constants';
import EmbeddedImage from '~/shared/components/RichText/components/EmbeddedImage';
import useViewportName from '~/shared/hooks/use-viewport-name';
import ShowcaseEmbeddedVideo from '~/shared/components/CaptionedShowcase/components/ShowcaseEmbeddedVideo';

const mediaStyles = ({
  theme,
  imagePresetSize,
  splitLayoutVerticalImageAlignment,
}: {
  theme?: Theme;
  imagePresetSize?: ImagePresetSize;
  splitLayoutVerticalImageAlignment?: SplitLayoutVerticalImageAlignment;
}): SerializedStyles => css`
  align-self: ${splitLayoutVerticalImageAlignment === ALIGNMENT.TOP
    ? 'flex-start'
    : 'center'};
  width: ${imagePresetSize === PRESET_IMAGE_SIZE.ICON
    ? theme.spacings.zetta
    : '40%'};
`;

const StyledMedia = styled('div')(mediaStyles);

export interface ColumnProps {
  column?: ColumnType;
  fullWidthButtons?: boolean;
  alignAllButtons?: boolean;
  index?: number;
  numberOfColumns?: number;
  alignments?: Alignments;
  imagePreset?: ImagePreset;
  theme?: Theme;
  isFirstSectionWithoutHeadline?: boolean;
}

export const Column: FC<ColumnProps> = ({
  column = {},
  alignments,
  fullWidthButtons,
  alignAllButtons,
  index,
  imagePreset = {},
  numberOfColumns,
  isFirstSectionWithoutHeadline,
}) => {
  const viewportName = useViewportName();
  const taggerInfo = React.useMemo(() => {
    if (
      !column.taggingAction ||
      !column.taggingEntity ||
      !column.taggingTrigger
    ) {
      return null;
    }

    return {
      action: column.taggingAction,
      entity: column.taggingEntity,
      trigger: column.taggingTrigger,
      name: column.taggingProperties?.name || null,
    };
  }, [column]);

  if (isEmpty(column.content)) {
    return null;
  }

  const hasSplitLayout = ColumnLayoutService.hasSplitLayoutForViewport(
    viewportName,
    column,
  );

  const trackingContentEntry = {
    contentType: column.contentType,
    contentEntryName: column.name,
    contentEntryId: column.id,
  };

  const [media, enrichedColumn] = ColumnLayoutService.enrichColumn({
    fullWidthButtons,
    hasSplitLayout,
  })(column, hasSplitLayout);
  const mediaProperties = get('data.target', media);

  const { imagePresetSize } = imagePreset;

  // The splitLayoutVerticalImageAlignment variable also applies to showcaseEmbeddedVideo
  const splitLayoutVerticalImageAlignment =
    column.splitLayoutVerticalImageAlignment || ALIGNMENT.TOP;

  return (
    <>
      {hasSplitLayout &&
      mediaProperties.contentType === 'showcaseEmbeddedVideo' ? (
        <StyledMedia
          splitLayoutVerticalImageAlignment={splitLayoutVerticalImageAlignment}
          imagePresetSize={imagePresetSize}
        >
          <ShowcaseEmbeddedVideo {...mediaProperties} isOnRichtext />
        </StyledMedia>
      ) : null}

      {hasSplitLayout && mediaProperties.contentType === 'genericImage' ? (
        <StyledMedia
          splitLayoutVerticalImageAlignment={splitLayoutVerticalImageAlignment}
          imagePresetSize={imagePresetSize}
        >
          <EmbeddedImage {...mediaProperties} />
        </StyledMedia>
      ) : null}
      <RichtextColumn
        {...enrichedColumn}
        index={index}
        splitLayout={hasSplitLayout}
        splitLayoutVerticalImageAlignment={splitLayoutVerticalImageAlignment}
        alignments={alignments}
        trackingContentEntry={trackingContentEntry}
        imagePreset={imagePreset}
        numberOfColumns={numberOfColumns}
        taggerInfo={taggerInfo}
        alignAllButtons={alignAllButtons}
        isFirstSectionWithoutHeadline={isFirstSectionWithoutHeadline}
      />
    </>
  );
};
